
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchBoxDialogRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog' }, _createElement('div', { 'className': 'clear-self cm_search-box_container' }, _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'navUser-action dialog-content',
        'onSubmit': this.preventFormSubmission
    }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title' }, 'Search'), _createElement('div', {
        'className': 'cm_search-box-dialog_close-button close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'image',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' })))), _createElement('div', { 'className': 'cm_search-box_form-container form-field' }, [
        [this.searchInput(function () {
                return _createElement('div', { 'className': 'form-input cm_searchInput' }, 'Search for a product');
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('span', {
            'className': 'cm_search-box_clear-container',
            'key': '6072'
        }, this.inputNotEmpty ? _createElement('span', {
            'className': 'cm_search-box_clear',
            'onClick': this.clearInput,
            'key': '919'
        }, '\n    ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n  ') : null),
        _createElement('div', {
            'className': 'cm_search-box_submit btn-search',
            'title': 'Search',
            'aria-label': 'search button',
            'data-cm-role': 'add-query',
            'key': '6074'
        }, [_createElement('svg', {
                'className': 'icon',
                'key': '12180'
            }, _createElement('use', { 'xlinkHref': '#icon-search' }))])
    ]), [this.dropdown(function () {
            function repeatSections1(sections, sectionsIndex) {
                return [sections(function () {
                        function repeatItems1(items, itemsIndex) {
                            return [items(function () {
                                    function mergeProps_text(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function mergeProps_product(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function repeatI2_product(i, iIndex) {
                                        return _createElement('span', {
                                            'key': i,
                                            'className': 'icon icon--' + (this.review_average > i ? 'ratingFull' : 'ratingEmpty')
                                        }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-star' })));
                                    }
                                    return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'product' }, _createElement('article', { 'className': 'card' }, _createElement('figure', { 'className': 'card-figure' }, _createElement('a', { 'className': 'card-link' }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                                        'className': 'card-image lazyautosizes lazyloaded',
                                        'data-sizes': 'auto',
                                        'src': this.imageOrDefault(this.images_image_thumbnail1),
                                        'data-src': this.imageOrDefault(this.images_image_thumbnail1),
                                        'alt': this.removeHTML(this.name),
                                        'title': this.removeHTML(this.name),
                                        'sizes': '50px'
                                    })))), _createElement('div', { 'className': 'card-body' }, _createElement('p', mergeProps_product({
                                        'className': 'card-brand',
                                        'data-test-info-type': 'brandName'
                                    }, { dangerouslySetInnerHTML: { __html: this.brand_name } })), _createElement('h4', { 'className': 'card-title' }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('div', { 'className': 'price-wrapper' }, _createElement('div', {
                                        'className': 'card-price',
                                        'data-test-info-type': 'price'
                                    }, _createElement('div', {
                                        'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                                        'style': { display: 'none' }
                                    }, _createElement('span', {
                                        'data-product-rrp-price-without-tax': true,
                                        'className': 'price price--rrp'
                                    })), _createElement('div', { 'className': 'price-section price-section--withoutTax non-sale-price--withoutTax ' + (this.regular_price ? '' : 'price-none') }, _createElement('span', {
                                        'data-product-non-sale-price-without-tax': true,
                                        'className': 'price price--non-sale'
                                    }, '\n                      ', this.formatPrice(this.regular_price), '\n                    ')), _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('span', {
                                        'data-product-price-without-tax': true,
                                        'className': 'price price--withoutTax'
                                    }, '\n                      ', this.formatPrice(this.price), '\n                    ')))), _createElement('p', {
                                        'className': 'card-rating',
                                        'data-test-info-type': 'productRating'
                                    }, _createElement.apply(this, [
                                        'span',
                                        { 'className': 'rating--small' },
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI2_product.bind(this))
                                    ]), this.review_count > 0 ? _createElement('span', {
                                        'className': 'number-review',
                                        'key': '2351'
                                    }, '(', this.review_count, ')') : null))))) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, _createElement('span', { dangerouslySetInnerHTML: { __html: this.value } }), this.hitCount ? _createElement('span', {
                                        'className': 'hit-count',
                                        'key': '120'
                                    }, '(', this.hitCount, ')') : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'section' }, this.sectionTitle ? _createElement('div', {
                            'className': 'item section-title',
                            'key': '29'
                        }, _createElement('b', {}, this.totalHits > 1 ? `${ this.totalHits } ` : '', this.sectionTitle), !!this.vehicleString ? ` for ${ this.vehicleString }` : '') : null, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_items' },
                            _map(this.items, repeatItems1.bind(this))
                        ]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'dialog-body cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_sections' },
                _map(this.sections, repeatSections1.bind(this))
            ]), [this.browseAllButton(function () {
                    return _createElement('div', { 'className': 'item button-item cm_browseAllButton' }, 'Browse all ', this.totalHits, ' products');
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]))));
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown"]